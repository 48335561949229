import { Component } from '@angular/core';

export class FederalLandDigitializetionStats {
  constructor(public name: string, public allRequirements: number, public mobileFriendly: number, public httpsSupported: number, public websitesFound: number) {}
};

const ByFederalLand = [
  new FederalLandDigitializetionStats('Berlin', 100, 100, 100, 100),
  new FederalLandDigitializetionStats('Bremen', 100, 100, 100, 100),
  new FederalLandDigitializetionStats('Hamburg', 100, 100, 100, 100),
  new FederalLandDigitializetionStats('Nordrhein-Westfalen', 75, 80, 95, 100),
  new FederalLandDigitializetionStats('Saarland', 75, 75, 100, 100),
  new FederalLandDigitializetionStats('Mecklenburg-Vorpommern', 71, 81, 88, 100),
  new FederalLandDigitializetionStats('Baden-Württemberg', 69, 80, 81, 100),
  new FederalLandDigitializetionStats('Bayern', 61, 68, 82, 100),
  new FederalLandDigitializetionStats('Hessen', 57, 62, 86, 100),
  new FederalLandDigitializetionStats('Niedersachsen', 57, 65, 88, 98),
  new FederalLandDigitializetionStats('Schleswig-Holstein', 56, 69, 82, 96),
  new FederalLandDigitializetionStats('Brandenburg', 52, 62, 73, 96),
  new FederalLandDigitializetionStats('Rheinland-Pfalz', 49, 61, 75, 100),
  new FederalLandDigitializetionStats('Sachsen', 44, 48, 84, 100),
  new FederalLandDigitializetionStats('Thüringen', 43, 58, 70, 94),
  new FederalLandDigitializetionStats('Sachsen-Anhalt', 40, 44, 50, 90),
];

@Component({
  templateUrl: './digitization-state-part-1.component.html',
  styleUrls: ['./digitization-state-part-1.component.scss']
})
export class DigitizationStatePart1Component {
  public dataByFederalLand = ByFederalLand;
  public dataByFederalLandColumns = Object.keys(ByFederalLand[0]);

  constructor() {
  }
}