<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" >
  <mat-sidenav-container>
  
    <mat-sidenav #snav (click)="snav.toggle()">
      <mat-nav-list>
        <a mat-list-item  class="menu-item" [class.active]="ngPath === nav.link" [routerLink]="nav.link" *ngFor="let nav of navMenu">{{ nav.name }}</a>
        <!-- a hack to make these strings into the i18n dictionary -->
        <span i18n="@@AboutUs" fxHide>About us</span>
        <span i18n="@@Blog" fxHide>Blog</span>
        <span i18n="@@Career" fxHide>Career</span>
        <span i18n="@@SEOTitle" fxHide>digital best practices for towns</span>
        <span i18n="@@SEODescription" fxHide>We help towns achieve their digitalization goals.</span>
      </mat-nav-list>
    </mat-sidenav>
  
    <mat-sidenav-content>
      <mat-toolbar class="toolbar">
        <div class="container">
          <div fxflex fxLayout="row" fxLayoutGap="16px">
            <div fxFlex="50">
              <h1 class="logo">
                <a routerLink="/">
                  <span class="r1">R1</span>
                  <span class="rationality">rationality</span>
                </a>
              </h1>
            </div>
            <div fxFlex="50" class="menu">
              <span fxShow fxHide.xs fxHide.sm>
                <a [routerLink]="nav.link" class="menu-item" [class.active]="ngPath === nav.link" *ngFor="let nav of navMenu">{{ nav.name }}</a>
              </span>
              <a href="/en{{ fullPath.slice(3) }}" aria-label="English language" class="menu-item" *ngIf="fullPath.substring(0, 3) !== '/en'">
                <svg width="25" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30">
                  <clipPath id="t">
                  <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"/>
                  </clipPath>
                  <path d="M0,0 v30 h60 v-30 z" fill="#00247d"/>
                  <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" stroke-width="6"/>
                  <path d="M0,0 L60,30 M60,0 L0,30" clip-path="url(#t)" stroke="#cf142b" stroke-width="4"/>
                  <path d="M30,0 v30 M0,15 h60" stroke="#fff" stroke-width="10"/>
                  <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" stroke-width="6"/>
                </svg>
                <span fxHide.xs fxHide.sm>English</span>
              </a>
              <a href="/de{{ fullPath.slice(3) }}" aria-label="Deutsche Sprache" class="menu-item" *ngIf="fullPath.substring(0, 3) !== '/de'">
                <svg width="25" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 3">
                  <desc>Flag of Germany</desc>
                  <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000"/>
                  <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00"/>
                  <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00"/>
                </svg>
                <span fxHide.xs fxHide.sm>Deutsch</span>
              </a>
              <span fxShow fxHide.md fxHide.lg fxHide.xl>
                <button mat-icon-button aria-label="menu" (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
              </span>
            </div>
          </div>
        </div>
      </mat-toolbar>

      <div class="content">
        <a name="top" id="top"></a>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
        <section class="contact" fxflex fxLayout="row">
          <div class="container">
          <a name="contact"></a>
          <div fxflex fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
              <div fxFlex="100">
              <h3 i18n>Email Us</h3><p>info@r1rationality.com</p>
              </div>
              <div fxFlex="100">
              <h3 i18n>Call Us</h3><p>+49-1512-6698549</p>
              </div>
              <div fxFlex="100">
              <h3 i18n>Visit Us</h3><p>Kantstr. 130, 10625, Berlin</p>
              </div>
          </div>
          </div>
        </section>

        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
          <p i18n>
            Privacy policy: this website does not use cookies and does not collect personal information.
          </p>
        </div>
      </div>

    </mat-sidenav-content>
  
  </mat-sidenav-container>
  
</div>
