<section class="story">
  <div class="container">
    <h1 i18n>State of Digitization of German Towns, Part 1: Basics.</h1>
    <div class="redline"></div>
    <div>
      <div class="i-story">
        <img src="assets/overall-stats.png" alt="summarizing statistics of the state of the basics of digitization of german towns">
      </div>
      <h3><span class="stat-number">43% </span> <span class="normal-text" i18n>of towns do not satisfy at least one basic requirement:</span></h3>
      <div class="more-stats" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
        <div fxFlex="auto" class="stat-number">
          <span>33%</span>
        </div>
        <div fxFlex="1 1 75%" class="small-text">
          <span i18n>of towns do not have a mobile-friendly version.</span>
        </div>
      </div>
      <div class="more-stats" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
        <div fxFlex="auto" class="stat-number">
          <span>20%</span>
        </div>
        <div fxFlex="1 1 75%" class="small-text">
          <span i18n>of towns do not support encrypted data transfer.</span>
        </div>
      </div>
      <div class="more-stats last" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
        <div fxFlex="auto" class="stat-number">
          <span>1.5%</span>
        </div>
        <div fxFlex="1 1 75%" class="small-text">
          <span i18n>of towns do not have a website findable in Google.</span>
        </div>
      </div>
      <p i18n>
        Some towns have more than one problem, so the numbers can't be added. We explain our research method in Methodology section below.
      </p>

      <h2 i18n>What These Numbers Mean?</h2>
      <div class="i-story">
        <img src="assets/what-these-numbers-mean.png" alt="the meaning of the summarizing statistics">
      </div>
      <p i18n>
        In 2017, Germany passed a law guaranteeing that by the end of 2022 all government services would be available in a digital form.
        In our opinion, the above numbers mean that the federal goverment drammatically underestimates the complexity of digitization and the readiness of individual towns.
      </p>
      <p i18n>
        If any of these basics are missing, then it's too early for political leaders to speak about digitization of services and fancy things,
        such as application of smart city concepts or methods of Artificial Intelligence.
      </p>
      <p i18n>
        The basics must be fixed first.
      </p>
      
      <h2 i18n>State of Digitization by Federal Land</h2>
      <table mat-table [dataSource]="dataByFederalLand">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef i18n>Name</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="allRequirements">
          <th mat-header-cell *matHeaderCellDef i18n>All Basics Present</th>
          <td mat-cell *matCellDef="let element">{{ element.allRequirements }}%</td>
        </ng-container>

        <ng-container matColumnDef="mobileFriendly">
          <th mat-header-cell *matHeaderCellDef i18n>Mobile-Friendly</th>
          <td mat-cell *matCellDef="let element">{{ element.mobileFriendly }}%</td>
        </ng-container>

        <ng-container matColumnDef="httpsSupported">
          <th mat-header-cell *matHeaderCellDef i18n>HTTPS Supported</th>
          <td mat-cell *matCellDef="let element">{{ element.httpsSupported }}%</td>
        </ng-container>

        <ng-container matColumnDef="websitesFound">
          <th mat-header-cell *matHeaderCellDef i18n>Websites Found</th>
          <td mat-cell *matCellDef="let element">{{ element.websitesFound }}%</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="dataByFederalLandColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: dataByFederalLandColumns;"></tr>
      </table>

      <h2 i18n>Methodology</h2>
      <div class="i-story">
        <img src="assets/methodology.png" alt="about methodology">
      </div>
      <ul>
        <li>
          <span i18n>
            We used a freely-downloadable database of German towns organized by postal codes.
            The database is available at
          </span>&nbsp;<a href="https://www.suche-postleitzahl.org/downloads" rel="nofollow">suche-postleitzahl.org/downloads</a>.
        </li>
        <li i18n>
          We took a random sample of 600 towns and analyzed them.
          In addition, to get the statistics per federal land we added additional random towns
          until our sample contained at least 20 towns per federal land or all towns from that federal land.
        </li>
        <li i18n>
          To find a town's website, we used a Google query "town-name postal-code offizielle Website" and analyzed first 10 results.
          In cases when a town was part of a union of towns (Gemeindeverband) and there was no website for the town itself, we took the website of the union of towns.
        </li>
        <li i18n>
          To check for mobile-friendliness, we used Google's Mobile-Friendly Test API.
          In addition, we manually reviewed a random sample of Google's responses to verify them.
        </li>
        <li i18n>
          To check for the support of encrypted data transfers, we looked at the URLs suggested by Google. 
          Google promotes https usage, so whenever https is available, Google suggests https URLs first.
          In addition, we manually reviewed a random sample of the websites and probed for the presense of https.
        </li>
        <li i18n>
          This research was conducted in April of 2020.
        </li>
      </ul>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
        <div fxFlex="60">
          <h2 i18n>Access to the Data</h2>
          <p i18n>
            We are happy to share additional data based on this research with journalists, researchers, and anyone else who would like to use the data for the public benefit.
          </p>
          <p i18n>
            If you see how you can benefit from the data, do send us an email.
          </p>
        </div>
        <div fxFlex="40">
          <div class="i-story">
            <img src="assets/access-to-the-data.png" alt="the meaning of the summarizing statistics">
          </div>    
        </div>
      </div>
      
    </div>
  </div>
</section>