import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './town-small.component.html',
  styleUrls: [ './town-small.component.css'],
  selector: 'app-town-small'
})
export class TownSmallComponent implements OnInit {
  @Input() name: string;
  @Input() location: string;
  @Input() youtubeId: string;
  public youtubeLink: SafeUrl;

  constructor(private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    const link = 'https://www.youtube.com/embed/' + this.youtubeId + '?autoplay=0&origin=http://r1rationality.com';
    this.youtubeLink = this.domSanitizer.bypassSecurityTrustResourceUrl(link);
    console.log(this.youtubeId, this.youtubeLink);
  }
}
