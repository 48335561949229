import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitizationStatePart1Component } from './digitization-state-part-1/digitization-state-part-1.component';
import { DigitizationStatePart2Component } from './digitization-state-part-2/digitization-state-part-2.component';
import { HomeComponent } from './home/home.component';
import { InformationSharingComponent } from './information-sharing/information-sharing';
import { BlogPage } from './blog/blog.page';
import { CareerComponent } from './career/career';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'blog', component: BlogPage },
  { path: 'career', component: CareerComponent },
  { path: 'digital-information-sharing', component: InformationSharingComponent },
  { path: 'state-of-german-towns-digitization-part-1', component: DigitizationStatePart1Component },
  { path: 'state-of-german-towns-digitization-part-2', component: DigitizationStatePart2Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    enableTracing: false,
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0]
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
