<section class="mission" fxflex fxLayout="row">
    <div class="container">
        <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start center">
            <div fxFlex="40" fxFlexOrder.lt-md="2">
                <h1 i18n>Our Mission</h1>
                <h2 i18n>We help towns achieve digitalization goals by making digital best practices easy to provide.</h2>
                <a href="#contact" mat-raised-button class="hello" i18n>Say Hello</a>
            </div>
            <div fxFlex="10" fxHide.lt-md="true"></div>
            <div fxFlex="50" fxHide.lt-md="true">
                <img src="assets/i-mission.png" alt="our mission image" width="100%">
            </div>
        </div>
    </div>
    <div class="mouse"></div>
</section>

<section class="solutions">
  <div class="container">
    <h2 i18n>Our Solutions</h2>
    <div class="redline"></div>

    <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50">
        <img src="assets/i-compatible-and-compliant.png" alt="An illustration for information and service discovery portal for towns section" width="100%">
      </div>
      <div fxFlex="50">
        <h3 i18n>Information and service discovery portal for towns</h3>
        <p i18n>An easy-to-integrate open-source town-portal software, in which search, the ease of information discovery, and accessibility are first class citizens.</p>
        <a routerLink="/digital-information-sharing" mat-raised-button class="hello" i18n>Learn more</a>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50">
        <app-town-small name="Tönisvorst" location="Nordrhein-Westfalen, Germany" youtubeId="_FvS3DyTej8"></app-town-small>
      </div>
      <div fxFlex="50">
        <app-town-small name="Grefrath" location="Baden-Württemberg, Germany" youtubeId="AR9CmUUEU4g"></app-town-small>
      </div>
    </div>
  </div>
</section>

<section class="use-cases">
  <div class="container">
    <h2 i18n>Coming Solutions</h2>
    <div class="redline"></div>
    <div fxflex fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50">
        <div class="usecase scheduling">
          <span class="icon"></span>
        </div>
        <h3 i18n>User-friendly scheduling of appointments</h3>
        <p i18n>Coming soon &hellip;</p>
      </div>
      <div fxFlex="50">
        <div class="usecase services">
          <span class="icon"></span>
        </div>
        <h3 i18n>Fully-digital municipal-service delivery</h3>
        <p i18n>Coming soon &hellip;</p>
      </div>
    </div>
  </div>
</section>

<section class="story">
  <div class="container">
    <h2 i18n>Our Blog</h2>
    <div class="redline"></div>
    <div class="background">
      <div fxflex fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch">
        <div fxFlex="10"></div>
        <div fxFlex="80" class="teaser">
            <h2 i18n>State of Digitization of German Towns, Part 2: Search.</h2>
            <p i18n>
              In June 2020, we checked one thousand randomly-selected towns and analyzed if their onsite search is up to the challenge.
            </p>
            <a mat-raised-button class="hello" routerLink="/state-of-german-towns-digitization-part-2" i18n>Learn more</a>
        </div>
        <div fxFlex="10"></div>
      </div>
    </div>
    <div class="background">
      <div fxflex fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch">
        <div fxFlex="10"></div>
        <div fxFlex="80" class="teaser">
            <h2 i18n>State of Digitization of German Towns, Part 1: Basics.</h2>
            <p i18n>
              In 2017, Germany passed a law guaranteeing that by the end of 2022 all government services would be available in a digital form.
              In April of 2020, we conducted a research checking for the basics of digitization of towns.
            </p>
            <a mat-raised-button class="hello" routerLink="/state-of-german-towns-digitization-part-1" i18n>Learn more</a>
        </div>
        <div fxFlex="10"></div>
      </div>
    </div>
  </div>
</section>

<section class="team">
  <div class="container">
    <h2 i18n>Our Founders</h2>
    <div class="redline"></div>

    <!-- Alex -->
    <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start stretch" fxLayoutGap="16px" class="row">
      <div fxFlex="50" fxFlexOrder.lt-md="2">
        <h3>Alex Sierkov, <span i18n>Founder</span></h3>
        <div>
          <h3 i18n>Experience</h3>
          <p>
            Director of Product, Search and Machine Learning,<br>
            HomeToGo GmbH, Berlin, Germany.
          </p>
          <p>
            Product Manager, Big Data and Machine Learning,<br>
            Uber&nbsp;(NYSE:&nbsp;UBER), San Francisco, United States.
          </p>
          <p>
            Head of Department for Statistics and Analytics,<br>
            Yandex&nbsp;(NASDAQ:&nbsp;YNDX), Moscow, Russia.
          </p>
        </div>
        <div>
          <h3 i18n>Education</h3>
          <p>
            University&nbsp;of&nbsp;California at&nbsp;Berkeley,<br>
            Haas&nbsp;School&nbsp;of&nbsp;Business,<br>
            Master of Business Administration.
          </p>
          <p>
            National&nbsp;Technical University&nbsp;of&nbsp;Ukraine<br>
            “Igor&nbsp;Sikorsky Kyiv&nbsp;Polytechnic&nbsp;Institute”,<br>
            Bachelor of Engineering.
          </p>
        </div>
        <div>
          <h3 i18n>Languages spoken</h3>
          <p i18n>English, German, Russian, Ukrainian</p>
        </div>
        <div class="links">
          <h3 i18n>LinkedIn Profile</h3>
          <a href="https://www.linkedin.com/in/alexsierkov/" aria-label="Alex Sierkov's LinkedIn profile" rel="nofollow">
            <svg width="24" height="24" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85
                      3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064
                      2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2
                      24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
            </svg>
          </a>
        </div>
      </div>
      <div fxFlex="50">
        <div>
          <img alt="Alex Sierkov" src="assets/alex-sierkov.jpg" width="100%">
        </div>
        <div>
          <br>
          <p i18n>
            With more than 15 years of experience of building High-Tech products, I have done it all from consumer-facing products
            to BigData infrastructure and Artificial Intelligence.
          </p>
          <p i18n>
            My favourite quote is Albert Einstein's &ldquo;If I had an hour to solve a problem, I'd spend 55 minutes thinking about the problem and five minutes thinking about solutions&rdquo;.
          </p>
        </div>
      </div>
    </div>

    <!-- Dzhangar -->
    <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start stretch" fxLayoutGap="16px" class="row">
      <div fxFlex="50">
        <div>
          <img alt="Dzhangar Sanzhiev" src="assets/dzhangar-sanzhiev.jpg" width="100%">
        </div>
        <br>
        <p i18n>
          Throughout the last 13 years, I’ve helped hundreds of organizations in Europe  achieve their objectives through people and digital technologies.
          I’ve helped customers transform their organizations through the use of online learning platforms, digital assessments and succession management software.
        </p>
        <p i18n>
          My goal is to leverage these experiences to help communities respond to the needs of citizens by bringing their digital practices up-to-speed.
        </p>
      </div>
      <div fxFlex="50">
        <h3>Dzhangar Sanzhiev, <span i18n>Co-founder</span></h3>
        <div>
          <h3 i18n>Experience</h3>
          <p>
            Development Dimensions International, Germany<br>
            Director Client Solutions
          </p>
          <p>
            Development Dimensions International, Russia<br>
            Country Manager
          </p>
        </div>
        <div>
          <h3 i18n>Education</h3>
          <p>
            Russian State Social University<br>
            Masters&nbsp;degree&nbsp;in Intercultural&nbsp;Communication and&nbsp;Linguistics.
          </p>
        </div>
        <div>
          <h3 i18n>Languages spoken</h3>
          <p i18n>
            English, German, Russian.
          </p>
        </div>
        <div class="links">
          <h3 i18n>LinkedIn Profile</h3>
          <a href="https://www.linkedin.com/in/dzhangar-sanzhiev-b3923931/" aria-label="Dzhangar Sanzhiev's LinkedIn profile" rel="nofollow">
            <svg width="24" height="24" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85
                      3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064
                      2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2
                      24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="strategy">
  <div class="container">
  <h2 i18n>Our Strategy</h2>
  <div class="redline"></div>
  <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" fxFlexOrder.lt-md="2" class="i-fingerprint">
          <ul>
              <li i18n>Focus on digital solutions allowing for metric-driven optimization.</li>
              <li i18n>Catalog community problems that already support metric-driven optimization.</li>
              <li i18n>Develop new metrics to make more community problems supported.</li>
              <li i18n>Promote best-practice solutions for supported problems.</li>
              <li i18n>Develop integrated solutions to accelerate the adoption of best practices.</li>
          </ul>
      </div>
      <div fxFlex="50">
          <img alt="an image for section our strategy" src="assets/i-strategy.png" width="100%">
      </div>
  </div>
  </div>
</section>
