<div class="dummy"></div>

<section class="story">
  <div class="container">
    <div class="background">
      <div fxflex fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch">
        <div fxFlex="10"></div>
        <div fxFlex="80" class="teaser">
            <h2 i18n>State of Digitization of German Towns, Part 2: Search.</h2>
            <p i18n>
              In June 2020, we checked one thousand randomly-selected towns and analyzed if their onsite search is up to the challenge.
            </p>
            <a mat-raised-button class="hello" routerLink="/state-of-german-towns-digitization-part-2" i18n>Learn more</a>
        </div>
        <div fxFlex="10"></div>
      </div>
    </div>
  </div>
</section>

<section class="story">
  <div class="container">
    <div class="background">
      <div fxflex fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch">
        <div fxFlex="10"></div>
        <div fxFlex="80" class="teaser">
            <h2 i18n>State of Digitization of German Towns, Part 1: Basics.</h2>
            <p i18n>
              In 2017, Germany passed a law guaranteeing that by the end of 2022 all government services would be available in a digital form.
              In April of 2020, we conducted a research checking for the basics of digitization of towns.
            </p>
            <a mat-raised-button class="hello" routerLink="/state-of-german-towns-digitization-part-1" i18n>Learn more</a>
        </div>
        <div fxFlex="10"></div>
      </div>
    </div>
  </div>
</section>