<section class="promise">
  <div class="container" fxLayoutAlign="center center">
    <h1 i18n>Your town information and services, easily discoverable</h1>
  </div>
  <div class="mouse"></div>
</section>

<section class="search">
  <div class="container">
    <h2 i18n>Optimized search</h2>
    <div class="redline"></div>

    <div fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" class="valuepoint">
        <div class="box green">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Every page can be found</h3>
          <p i18n>Our systems daily check that all pages are classified and indexed.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box yellow">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Relevant content comes first</h3>
          <p i18n>We optimize search ranking for user success, starting from most frequent queries.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box red">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Language nuances are recognized</h3>
          <p i18n>The declination rules of languages are taken into account.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box orange">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Dynamic navigation</h3>
          <p i18n>Dynamic filters help users clarify their intent and quicker find relevant content.</p>
        </div>
      </div>
    </div>

    <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" fxFlexOrder.lt-md="2" class="i-fingerprint">
        <p i18n>
          A website of a typical town has more than a thousand pages. Big cities have hundreds of thousands.
          Static menu systems are not positioned well to deal with such volumes of content. So, users get lost.
          We bring the best practices for search and content discovery, battle-tested by Netflix and Amazon, to the service of your town.
        </p>
        <p>
          <span i18n>Learn more in</span>
          &nbsp;
          <a routerLink="/state-of-german-towns-digitization-part-2" i18n>our blog post about the state of search on websites of German towns</a>.
        </p>
      </div>
      <div fxFlex="50">
        <img src="assets/i-optimized-search.png" alt="An illustration for optimized search section" width="100%">
      </div>
    </div>

  </div>
</section>

<section class="compatible">
  <div class="container">
    <h2 i18n>Compatible and compliant website</h2>
    <div class="redline"></div>
    <div fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" class="valuepoint">
        <div class="box green">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Mobile-friendly</h3>
          <p i18n>Fully responsive design supporting desktop, tablet, and mobile devices.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box yellow">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Secure</h3>
          <p i18n>We ensure that SSL certificates are always up to date.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box red">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Accessible</h3>
          <p i18n>Compliant with EU and W3C web content accessibility guildelines.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box orange">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>GDPR ready</h3>
          <p i18n>Compliant with EU General Data Protection Regulation.</p>
        </div>
      </div>
    </div>

    <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" fxFlexOrder.lt-md="2" class="i-fingerprint">
        <p i18n>
          Our 2020 study of 1000 randomly-selected towns in Germany has shown that:
        </p>
        <ul>
          <li i18n>77% of town websites don't achieve at least a 90% web content accessibility score from Google.</li>
          <li i18n>31% of town websites are not mobile friendly.</li>
          <li i18n>20% of town websites don't support secure transmission of data over https.</li>
        </ul>
        <p>
          <span i18n>Learn more in</span>
          &nbsp;
          <a routerLink="/state-of-german-towns-digitization-part-1" i18n>our blog post about the state basic usability requirements on websites of German towns</a>.
        </p>
      </div>
      <div fxFlex="50">
        <img src="assets/i-compatible-and-compliant.png" alt="An illustration for compatible and compliant section" width="100%">
      </div>
    </div>

  </div>
</section>

<section class="ten-x">
  <div class="container">
    <h2 i18n>10x quicker and simpler setup</h2>
    <div class="redline"></div>

    <div fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" class="valuepoint">
        <div class="box green">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Save your team’s time</h3>
          <p i18n>We programmatically import all your existing content, so no support is required from  a town during the setup phase.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box yellow">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Easily change information architecture</h3>
          <p i18n>We automatically reclassify existing pages into the new user-friendly navigational hierarchy, making it easy to transfer thousands of pages in a matter of weeks instead of months.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box red">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Save money and time</h3>
          <p i18n>We rely on an open-source town-portal software that allows you to save money on licensing fees and custom development.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box orange">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Only few hours of your time to begin</h3>
          <p i18n>We prepare a pilot for free without any commitments or contracts.</p>
        </div>
      </div>
    </div>

    <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" fxFlexOrder.lt-md="2" class="i-fingerprint">
        <p i18n>
          Your team can focus on what really matters for your town while we make sure that your key content and services are: discoverable, look good, and work on all device types.
        </p>
      </div>
      <div fxFlex="50">
        <img src="assets/i-10x-quicker.png"  alt="An illustration for 10x quicker setup section" width="100%">
      </div>
    </div>

  </div>
</section>

<section class="open-source">
  <div class="container">
    <h2 i18n>Benefits of an open source ecosystem</h2>
    <div class="redline"></div>

    <div fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" class="valuepoint">
        <div class="box green">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Share the benefits</h3>
          <p i18n>Any new capability built for one of the towns is shared with all others.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box yellow">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Collaborate easily</h3>
          <p i18n>Towns can collaborate on special features, allowing for cost-sharing and cost-optimization.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box red">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Don’t lock yourself to one vendor</h3>
          <p i18n>Your town is not locked into a contract with a single service provider while any digital agency and your IT team can access and reuse the code.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box orange">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Comply with regulations</h3>
          <p i18n>The open code allows for public and government audits, helping to fulfill compliance and security requirements.</p>
        </div>
      </div>
    </div>

    <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" fxFlexOrder.lt-md="2" class="i-fingerprint">
        <p i18n>
          By subscribing to our services, you invest in the accelerated pace of development of digital services for all small towns in the EU!
        </p>
      </div>
      <div fxFlex="50">
        <img src="assets/i-open-source.png" alt="an illustration for benefits of open-source section" width="100%">
      </div>
    </div>

  </div>
</section>

<section class="safe-choice">
  <div class="container">
    <h2 i18n>Safe and pragmatic choice</h2>
    <div class="redline"></div>

    <div fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" class="valuepoint">
        <div class="box green">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Easy to decide</h3>
          <p i18n>Decide if our solution is good for your town based on a fully-featured prototype.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box yellow">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Enjoy many support options</h3>
          <p i18n>Have many options for future maintenance, allowing safety and cost optimizations: your IT team, a digital agency, a digital consultant from R1 Rationality project.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box red">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Simple terms</h3>
          <p i18n>A simple monthly subscription, allowing for forecasting of your expenses. A cancellable contract, allowing you to change your decision any time.</p>
        </div>
      </div>
      <div fxFlex="50" class="valuepoint">
        <div class="box orange">
          <span class="icon"></span>
        </div>
        <div>
          <h3 i18n>Stay up to date</h3>
          <p i18n>All improvements of the underlying open source platform can be automatically delivered for your town if you choose so.</p>
        </div>
      </div>
    </div>

    <div fxflex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50" fxFlexOrder.lt-md="2" class="i-fingerprint">
        <p i18n>
          A modern pragmatic solution optimized for the needs of smaller towns.
        </p>
      </div>
      <div fxFlex="50">
        <img src="assets/i-safe-choice.png" alt="An illustration for safe and pragmatic choice section" width="100%">
      </div>
    </div>

  </div>
</section>

<section class="examples">
  <div class="container">
    <h2 i18n>Examples</h2>
    <div class="redline"></div>

    <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50">
        <app-town-small name="Tönisvorst" location="Nordrhein-Westfalen, Germany" youtubeId="_FvS3DyTej8"></app-town-small>
      </div>
      <div fxFlex="50">
        <app-town-small name="Grefrath" location="Nordrhein-Westfalen, Germany" youtubeId="AR9CmUUEU4g"></app-town-small>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50">
        <app-town-small name="Stadt Lauf an der Pegnitz" location="Nürnberger Land, Bayern, Germany" youtubeId="ROTA7bNjZ-E"></app-town-small>
      </div>
      <div fxFlex="50">
        <app-town-small name="Steinach" location="Ortenaukreis, Baden-Württemberg, Germany" youtubeId="KMg2eCsjU7E"></app-town-small>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50">
        <app-town-small name="Samtgemeinde Gieboldehausen" location="Göttingen, Niedersachsen, Germany" youtubeId="nLL7AnjaP6I"></app-town-small>
      </div>
      <div fxFlex="50">
        <app-town-small name="Wachtendonk" location="Kleve, Nordrhein-Westfalen, Germany" youtubeId="Dv34X2LpNCU"></app-town-small>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50">
        <app-town-small name="Holzwickede" location="Nordrhein-Westfalen, Germany" youtubeId="4wsl611bYg4"></app-town-small>
      </div>
      <div fxFlex="50">
        <app-town-small name="Heimbach Eifel" location="Nordrhein-Westfalen, Germany" youtubeId="nBRBUdUJ0K0"></app-town-small>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50">
        <app-town-small name="Dörentrup Lippe" location="Nordrhein-Westfalen, Germany" youtubeId="oalNV6B5mTM"></app-town-small>
      </div>
      <div fxFlex="50">
        <app-town-small name="Schelklingen" location="Baden-Württemberg, Germany" youtubeId="fYVlwGL4JM4"></app-town-small>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutAlign="start stretch" class="row">
      <div fxFlex="50">
        <app-town-small name="Gröbenzell" location="Bayern, Germany" youtubeId="RdPZ_7TAgT0"></app-town-small>
      </div>
      <div fxFlex="50">
        <app-town-small name="Bedburg Hau" location="Nordrhein-Westfalen, Germany" youtubeId="PAl4c2X4gdU"></app-town-small>
      </div>
    </div>

  </div>
</section>
