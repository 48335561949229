<section class="story">
  <div class="container">

    <h1 i18n>How good are municipal portals at search?</h1>
    <div class="redline"></div>
    <div class="i-story">
      <img src="assets/i-how-good-are-towns-at-search.png" alt="summarizing statistics of the state of the basics of digitization of german towns">
    </div>

    <p i18n>
      Municipal websites are collections of diverse content, starting from announcements and ending with information about local events.
      Two thirds of German towns contain more than 500 pages, suggesting that a working onsite search can help citizens find the right content
      without getting lost in convoluted menus.
    </p>
    <p i18n>
      In June 2020, we checked one thousand randomly-selected towns and analyzed if their onsite search is up to the challenge.
    </p>

    <h2 i18n>The results</h2>
    <h3><span class="stat-number">98% </span> <span class="normal-text" i18n>of towns miss at least one modern search best practice:</span></h3>
    <div class="more-stats" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
      <div fxFlex="auto" class="stat-number">
        <span>89%</span>
      </div>
      <div fxFlex="1 1 75%" class="small-text">
        <span i18n>don’t have an automatic typo correction.</span>
      </div>
    </div>
    <div class="more-stats" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
      <div fxFlex="auto" class="stat-number">
        <span>72%</span>
      </div>
      <div fxFlex="1 1 75%" class="small-text">
        <span i18n>don't optimize for search results relevance: a search for "driver’s license" or "marriage" doesn't return relevant results at the top position.</span>
      </div>
    </div>
    <div class="more-stats" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
      <div fxFlex="auto" class="stat-number">
        <span>67%</span>
      </div>
      <div fxFlex="1 1 75%" class="small-text">
        <span i18n>don’t provide an autosuggest.</span>
      </div>
    </div>
    <div class="more-stats" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
      <div fxFlex="auto" class="stat-number">
        <span>57%</span>
      </div>
      <div fxFlex="1 1 75%" class="small-text">
        <span i18n>don’t have search snippets.</span>
      </div>
    </div>
    <div class="more-stats last" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
      <div fxFlex="auto" class="stat-number">
        <span>21%</span>
      </div>
      <div fxFlex="1 1 75%" class="small-text">
        <span i18n>don’t have an onsite search at all.</span>
      </div>
    </div>
    <p i18n>
      Some towns have more than one problem, so the numbers cannot be added.
    </p>

    <h2 i18n>What do these numbers mean?</h2>
    <div class="i-story">
      <img src="assets/i-what-these-numbers-mean-2.png" alt="the meaning of the summarizing statistics">
    </div>
    <p i18n>
      The above numbers mean that if towns were to provide all 575 online services selected by German federal government for implementation,
      citizens would have a major issue with finding them because the search systems don’t work very often.
    </p>
    <p i18n>
      In addition, this means that the time and opportunities lost by citizens who are not able to quickly find the relevant content are not seriously considered.
    </p>

    <h2 i18n>Why does this problem exist?</h2>
    <div class="i-story">
      <img src="assets/i-why-this-problem-exists.png" alt="the meaning of the summarizing statistics">
    </div>
    <p i18n>
      Many municipal websites were created decades ago with just a few pages. Over the years they kept adding content, and the moment when the traditional website menus stopped working was likely overlooked.
    </p>
    <p i18n>
      In addition, a robust onsite-search system requires a different technology stack and skill set from the town’s IT department. So, it’s no surprise that most towns are not able to solve this alone.
    </p>

    <h2 i18n>Why traditional methods fail?</h2>
    <div class="i-story">
      <img src="assets/i-why-traditional-methods-fail.png" alt="the meaning of the summarizing statistics">
    </div>
    <p i18n>
      When a town decides to improve its website, it often goes one of the two routes:
    </p>
    <ul>
      <li i18n>It relies on the in-house IT team.</li>
      <li i18n>It hires a local web-agency.</li>
    </ul>

    <p i18n>
      Both approaches have their issues, frequently resulting in projects lasting 12-18 months and
      costing tens of thousands euros while still not solving the problem fully.
    </p>

    <p i18n>Towns that go the in-house route often realize that:</p>
    <ul>
      <li i18n>They have limited access to IT talent experienced in configuring and maintaining search systems.</li>
      <li i18n>There are no integrated solutions designed specifically for towns.</li>
      <li i18n>There are many other tasks on the plate of the IT department, so search projects are often put on hold.</li>
    </ul>

    <p i18n>Towns that decide to hire a web-agency often see that:</p>
    <ul>
      <li i18n>They need to go through the purchasing process, so the price becomes the focal point.</li>
      <li i18n>Lost opportunities created for citizens from an underperforming system are often overlooked since it’s hard to put a EUR number on them.</li>
    </ul>

    <h2 i18n>What can be a 10x better solution?</h2>
    <div class="i-story">
      <img src="assets/i-10x-better-solution.png" alt="the meaning of the summarizing statistics">
    </div>
    <p i18n>A 10x-better solution needs to satisfy the following criteria:</p>
    <ul>
      <li i18n>Be an integrated solution focused on the needs of small towns.</li>
      <li i18n>Be an open-source solution to ensure the maximum synergies from learnings and contributions made by each town.</li>
      <li i18n>Be managed by a team experienced in search systems and information discovery.</li>
    </ul>

    <p i18n>This is exactly what we’ve created. Moreover, our solution can offer not just one but two 10x improvements:</p>
    <ul>
      <li i18n>Time to launch: weeks instead of 12+ months.</li>
      <li i18n>Setup costs: from free to thousands of euros instead of tens thousands.</li>
    </ul>

    <h2 i18n>An open source town portal</h2>
    <div class="i-story">
      <img src="assets/i-open-source-town-portal.png" alt="the meaning of the summarizing statistics">
    </div>
    <p>
      <span i18n>You can find more details on the GitLab page of our project:</span> <wbr>
      <a href="https://gitlab.com/r1-rationality/town-portal" target="_blank">R1&nbsp;Rationality's Open&nbsp;Source&nbsp;Town&nbsp;Portal</a>.
    </p>
    <p i18n>Here are the key features of our software:</p>
    <ul>
      <li i18n>A modern information discovery system, including fully-featured-search and modern-navigation systems.</li>
      <li i18n>An optimized presentation of popular content categories.</li>
      <li i18n>A fully-responsive user interface supporting all modern device types: smartphones, tablets, and computers.</li>
      <li i18n>A user interface honoring the latest Web Accessibility standards.</li>
      <li i18n>A solution built for an easy cloud deployment.</li>
    </ul>
    <p i18n>Take a look at our 1-minute overview video:</p>
    <iframe id="ytplayer" type="text/html" width="100%" height="360"
      src="https://www.youtube.com/embed/EXlbVCVY1pA?autoplay=0&origin=http://r1rationality.com" frameborder="0">
    </iframe>

    <h2 i18n>Can your town benefit?</h2>
    <div class="i-story">
      <img src="assets/i-can-your-town-benefit.png" alt="the meaning of the summarizing statistics">
    </div>
    <p i18n>
      Definitely! We’re expanding our early-access program to include more towns.
      So, if you know someone in the office of the mayor, please share this message and help the changes happen sooner rather than later!
    </p>

  </div>
</section>