<section class="story">
  <div class="container">
    <h2 i18n>Community Representative - North-Rhine Westphalia</h2>
    <h3 i18n>Requirements:</h3>
    <ul>
      <li i18n>You have lived for at least 5 years in a town in North-Rhine-Westphalia with a polulation of less than 20 thousand inhabitants.</li>
      <li i18n>You communicate effectively in English and German.</li>
      <li i18n>You enjoy learning new people and understanding their needs.</li>
      <li i18n>You understand well how to self-organize your daily work.</li>
    </ul>
    <h3 i18n>What you'll be doing:</h3>
    <ul>
      <li i18n>Connecting and building relationships with inhabitants and managers of towns in North-Rhine-Westphalia.</li>
      <li i18n>Analyzing digital solutions for the problems of towns.</li>
      <li i18n>Writing short reports and blog posts about the most interesting problems you've found.</li>
      <li i18n>Working fully remotely, relying on digital channels for communication with your team: E-Mail, Slack, and video calls.</li>
    </ul>
    <div class="cta">
      <a href="https://forms.gle/pi9bbR7LwybNs6ip7" mat-raised-button class="hello" i18n>Learn more</a>
  </div>
  </div>
</section>
