import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { BlogPage } from './blog/blog.page';
import { CareerComponent } from './career/career';
import { DigitizationStatePart1Component } from './digitization-state-part-1/digitization-state-part-1.component';
import { DigitizationStatePart2Component } from './digitization-state-part-2/digitization-state-part-2.component';
import { HomeComponent } from './home/home.component';
import { InformationSharingComponent } from './information-sharing/information-sharing';
import { TownSmallComponent } from './information-sharing/town-small/town-small.component';

registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [
    AppComponent,
    DigitizationStatePart1Component,
    DigitizationStatePart2Component,
    HomeComponent,
    InformationSharingComponent,
    BlogPage,
    CareerComponent,
    TownSmallComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatTableModule,
    MatToolbarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
