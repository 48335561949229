import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './digitization-state-part-2.component.html',
  styleUrls: ['./digitization-state-part-2.component.scss']
})
export class DigitizationStatePart2Component {

  constructor(private title: Title, private meta: Meta) {
    this.title.setTitle('R1 Rationality - ' + ($localize`How good are municipal portals at search?`) + '.');
    if (this.meta.getTag("name=description")) this.meta.removeTag("name=description");
    this.meta.addTag({ name: "description", content: $localize`In June of 2020, we analyzed a thousand randomly-selected German towns to see if the town portal search works.` });
  }
}