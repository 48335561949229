import { Location, ViewportScroller } from '@angular/common';
import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  public title = 'app';
  public events: string[] = [];
  public opened = false;
  public fullPath: string;
  public ngPath:  string;
  public navMenu = [
    { name: $localize`:@@AboutUs:` || "About", link: '/' },
    { name: $localize`:@@Blog:` || "Blog", link: '/blog' },
    { name: $localize`:@@Career:` || "Career", link: '/career' },
  ];
  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(public router: Router, public location: Location,
              public changeDetector: ChangeDetectorRef, private media: MediaMatcher,
              private viewportScroller: ViewportScroller,
              private titleService: Title, private metaService: Meta) {
    const updatePaths = () => {
      this.fullPath = location.prepareExternalUrl(location.path());
      this.ngPath = this.location.path() || "/";
      this.changeDetector.markForCheck();
      this.titleService.setTitle('R1 Rationality - ' + ($localize`:@@SEOTitle:` || "digital best practices for towns") + '.');
      this.metaService.addTag({ name: "description", content: $localize`:@@SEODescription:` || "We help towns achieve their digitalization goals." });
    };
    updatePaths();
    location.onUrlChange(updatePaths);

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetector.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onActivate(event) {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.viewportScroller.scrollToAnchor('top');
    if (document && document.getElementById) {
      document.getElementById('top').scrollIntoView();
    }
    this.changeDetector.detectChanges();
  }

}
